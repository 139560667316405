import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import DOMPurify from "dompurify";
import {
  faCircleNotch,
  faKey,
  faUser,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { useState } from "react";
import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;
export default function Login() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  let UserLogin = {
    username: "",
    password: "",
  };
  async function handleSubmit(event: any) {
    event.preventDefault();
    UserLogin.username = event.target.username.value;
    UserLogin.password = event.target.password.value;
    await tryLogin();
  }

  async function tryLogin() {
    setSubmitLoading(true);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(UserLogin),
          credentials: "include",
        },
      );
      const data: { status: boolean; message: string; userId: number } =
        await response.json();
      if (data.status) {
        setSubmitLoading(false);
        toast("Login successful", { type: "success" });
        setIsLoggedIn(true);
      } else {
        setSubmitLoading(false);
        toast(data.message, { type: "error" });
      }
    } catch (error) {
      setSubmitLoading(false);
      toast("Error logging in", { type: "error" });
    }
  }

  return (
    <>
      <div className={"window"}>
        {isLoggedIn ? (
          <form className={"formContainer"}>
            <h1>Logged in</h1>
            <p>You are logged in. Continue to dashboard.</p>
            <Link to={"/Dashboard"} className={"formOkButton"}>
              Dashboard
            </Link>
          </form>
        ) : (
          <form className={"formContainer"} onSubmit={handleSubmit}>
            <h1>Login</h1>
            <label className={"formLabel"}>
              <div className={"rollerDiv"}>
                <FontAwesomeIcon icon={faUserTie} />
                <div className={"rollerString"}>
                  <span className={"rollerText"} style={{ color: "white" }}>
                    Username:
                  </span>
                  <span className={"rollerText"} style={{ color: "white" }}>
                    E-Mail:
                  </span>
                </div>
                <span
                  className={"rollerNext"}
                  style={{ color: "white" }}
                ></span>
                <input
                  className={"formTextInput rollerInput"}
                  style={{ zIndex: 2 }}
                  type="text"
                  name="username"
                />
              </div>
            </label>
            <label className={"formLabel"} style={{ zIndex: 2 }}>
              <FontAwesomeIcon icon={faKey} />
              Password:
              <input
                className={"formTextInput"}
                type="password"
                name="password"
              />
            </label>
            {submitLoading ? (
              <button disabled={true} className={"formOkButton"}>
                <FontAwesomeIcon icon={faCircleNotch} spin={true} />
              </button>
            ) : (
              <input
                className={"formOkButton"}
                type={"submit"}
                value={"Login"}
              />
            )}
            <Link className={"textLink"} to={"/ForgotPassword"}>
              Forgot your password?
            </Link>
          </form>
        )}
      </div>
    </>
  );
}
