import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;

export default function ResetPassword() {
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const [submitLoading, setSubmitLoading] = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    if (event.target.password.value !== event.target.confirmPassword.value) {
      toast("Passwords do not match", { type: "error" });
      return;
    }
    // check if password is at least 8 characters long
    if (event.target.password.value.length < 8) {
      toast("Password must be at least 8 characters long", { type: "error" });
      return;
    }
    setSubmitLoading(true);
    const data = {
      userId,
      token,
      password: event.target.password.value,
    };
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );
      const result = await response.json();
      if (result.status) {
        toast("Password reset successful", { type: "success" });
      } else {
        toast("Error resetting password", { type: "error" });
      }
      setSubmitLoading(false);
    } catch (error) {
      toast("Error resetting password", { type: "error" });
      setSubmitLoading(false);
    }
  }

  return (
    <>
      <div className={"window"}>
        <form className={"formContainer"} onSubmit={handleSubmit}>
          <h1>Reset Password</h1>
          <label className={"formLabel"}>
            New Password
            <input
              className={"formTextInput"}
              type="password"
              name="password"
              required
            />
          </label>
          <label className={"formLabel"}>
            Confirm Password
            <input
              className={"formTextInput"}
              type="password"
              name="confirmPassword"
              required
            />
          </label>
          {submitLoading ? (
            <button disabled={true} className={"formOkButton"} type="submit">
              <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            </button>
          ) : (
            <button className={"formOkButton"} type="submit">
              Set New Password
            </button>
          )}
        </form>
      </div>
    </>
  );
}
