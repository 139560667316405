import { ToastContainer } from "react-toastify";
import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div className={"homeContBG"}></div>
      <div className={"homeContainer"}>
        <div className={"sect1container"}>
          {/*<h1>What's outside the simulation?</h1>*/}

          <h1>Hello</h1>
          <h4>
            Always <b style={{ color: "gold" }}>reload the page</b> when
            entering!
          </h4>
          <p>
            Before you start using this app,
            <b style={{ color: "aquamarine" }}> please </b>
            click the following button to learn more about it and how to get
            started.
          </p>
          <Link to={"/learnmore"} className={"formOkButton"}>
            Get started
          </Link>
        </div>
      </div>
    </>
  );
}
