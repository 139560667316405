//@ts-nocheck
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;

export default function Chat({ setMenu, setChat, setChatUsername }) {
  const [chats, setChats] = useState([]);
  const [chatsLoading, setChatsLoading] = useState(true);
  const [createChatLoading, setCreateChatLoading] = useState(false);
  // const [userSecret, setUserSecret] = useState("");

  useEffect(() => {
    async function getChats() {
      try {
        const response = await fetchInterceptor(
          apiEndpoint.getApiEndpoint() + "chat/chats",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status) {
          setChats(data.chats);
          setChatsLoading(false);
          // toast(data.message, { type: "success" });
        } else {
          toast(data.message, { type: "error" });
          setChatsLoading(false);
        }
      } catch (error) {
        toast("Error getting chats", { type: "error" });
        setChatsLoading(false);
      }
    }
    getChats();
  }, []);

  // async function askForUserSecret() {
  //   setUserSecret(
  //     //     ask for user secret
  //     prompt("Please enter your secret"),
  //   );
  // }
  //
  // await askForUserSecret();

  // async function setUserSecret() {
  //   try {
  //     const response = await fetchInterceptor(
  //       apiEndpoint.getApiEndpoint() + "chat/user-secret",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ userSecret }),
  //         credentials: "include",
  //       },
  //     );
  //     const data = await response.json();
  //     if (!data.status) {
  //       toast(data.message, { type: "error" });
  //     }
  //   } catch (error) {
  //     toast("Error setting user secret", { type: "error" });
  //   }
  // }

  async function createChat(event: any) {
    event.preventDefault();
    const username = event.target.username.value;
    setCreateChatLoading(true);

    // select user to chat with

    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "chat/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username }),
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status) {
        toast(data.message, { type: "success" });
        setCreateChatLoading(false);
        await getChats();
      } else {
        toast(data.message, { type: "error" });
        setCreateChatLoading(false);
      }
    } catch (error) {
      toast("Error creating chat", { type: "error" });
      setCreateChatLoading(false);
    }
  }

  return (
    <>
      <div className={"chatOverview"}>
        <button onClick={() => setMenu(0)} className={"chatBackArrow"}>
          <FontAwesomeIcon icon={faLeftLong} />
        </button>

        <form className={"chatUserSelect"} onSubmit={createChat}>
          <p style={{ color: "white" }}>Search for a user to start a chat</p>
          <input name={"username"} />
          {createChatLoading ? (
            <button style={{ marginTop: "20px" }} disabled={true}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </button>
          ) : (
            <button style={{ marginTop: "20px" }} type={"submit"}>
              Start a chat!
            </button>
          )}
        </form>
        {chatsLoading ? (
          <>
            <div className={"chatUser"}>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          </>
        ) : (
          <>
            {chats &&
              chats.map((chat) => {
                return (
                  <div key={chat.id} className={"chatUser"}>
                    <p>{chat.username}</p>
                    <button
                      onClick={() => {
                        setChat(chat.id);
                        setChatUsername(chat.username);
                        setMenu(2);
                      }}
                    >
                      Private chat
                    </button>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </>
  );
}
