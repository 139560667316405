import { useAuth } from "../account/AuthContext";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Chat from "./Chat";
import PrivateChat from "./chat/privateChat";
// Initialize Firebase
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import fetchInterceptor from "../shared/fetchInterceptor";
import { useDashboard } from "../shared/context/DashboardContext";
import RunningCount from "./runningCount/runningCount";

// Initialize Firebase Cloud Messaging and get a reference to the service
const apiEndpoint = ApiEndpoint;
const firebaseConfig = {
  apiKey: "AIzaSyCh00HyOOZz61pKZTMII4vK8BNb_xyCWtE",
  authDomain: "versemulti.firebaseapp.com",
  projectId: "versemulti",
  storageBucket: "versemulti.appspot.com",
  messagingSenderId: "172737558701",
  appId: "1:172737558701:web:081542c707659bfe796e24",
};
initializeApp(firebaseConfig);
// check support for push notifications

let messaging: any;
if (await isSupported()) {
  messaging = getMessaging();
}
//@ts-ignore
export default function Dashboard({ menuNum }) {
  const { isLoggedIn } = useAuth();
  const { menu, setMenu } = useDashboard();
  // const [menu, setMenu] = useState(0);
  const [chat, setChat] = useState(null);
  const [chatUsername, setChatUsername] = useState(null);
  const [loading, setLoading] = useState(true);
  const [notifySupported, setNotifySupported] = useState(false);

  async function getAll() {
    if (isLoggedIn) {
      await checkSupport();
    } else {
      toast("You are not logged in", { type: "error" });
    }

    async function checkSupport() {
      const supported = await isSupported();
      if (supported) {
        // check if is logged in
        // toast("Push notifications are supported", { type: "success" });
        setNotifySupported(true);
        await askForNotificationPermission();
        onMessage(messaging, (payload) => {
          toast("Message received. ", { type: "success" });
          // ...
        });
        // console.log("Push notifications are supported");
      } else {
        toast("Push notifications are not supported", { type: "error" });
        setLoading(false);
        // console.log("Push notifications are not supported");
      }
    }
    async function askForNotificationPermission() {
      await Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          // toast("Permission granted", { type: "success" });
          // console.log("Berechtigung erhalten");
          const app = initializeApp(firebaseConfig);

          await getToken(messaging, {
            vapidKey:
              "BGyJHGb2jU0tfUCHKQINLQTiXRZz5Kx9eqSXtCnxTWBZ-5Ok7ZwkW8K7OF5DNn3_-HvZSYtDepbZ6ZkaL5etuRk",
          })
            .then(async (currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                // toast("Token: " + currentToken, { type: "success" });
                await savePushToken(currentToken);
                // console.log("Token: " + currentToken);
                // toast("Token: " + currentToken, { type: "success" });
                // await savePushToken(currentToken);
              } else {
                // Show permission request UI
                toast("We need your permission to send you notifications.", {
                  type: "error",
                });
                setLoading(false);
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
        } else {
          console.log("Berechtigung nicht erteilt");
        }
      });
    }
    async function savePushToken(token: string) {
      try {
        const response = await fetchInterceptor(
          apiEndpoint.getApiEndpoint() + "user/save-push-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token }),
            credentials: "include",
          },
        );
        const data = await response.json();
        if (data.status) {
          // toast(data.message, { type: "success" });
          setLoading(false);
        } else {
          toast(data.message, { type: "error" });
          setLoading(false);
        }
      } catch (error) {
        toast("Error saving push token", { type: "error" });
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    getAll();
  }, []);

  return (
    <>
      <div className={"window"}>
        {isLoggedIn ? (
          <>
            {menu === 0 ? (
              <>
                {loading ? (
                  <>
                    <div className={"dash-content"}>
                      <div className={"formContainer"}>
                        <h1>Loading</h1>
                        <p>Please be patient.</p>
                        <FontAwesomeIcon icon={faSpinner} spin={true} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={"dash-content"}>
                      {!notifySupported && (
                        <div className={"formContainer"}>
                          <h1>No push notifications</h1>
                          <p>
                            This browser does not support push notifications.
                          </p>
                          <p>
                            Learn more about how this effects your experience.
                          </p>
                          <button
                            className={"formOkButton"}
                            onClick={() => {
                              window.location.href =
                                "/learnmore-pushnotifications";
                            }}
                          >
                            Learn more
                          </button>
                        </div>
                      )}
                      <form className={"formContainer"}>
                        <h1>Chat</h1>
                        <p>Interested in secure and private messaging?</p>
                        <button
                          className={"formOkButton"}
                          onClick={() => setMenu(1)}
                        >
                          Chat
                        </button>
                      </form>
                      <form
                        className={"formContainer"}
                        style={{ marginTop: "50px" }}
                      >
                        <h1>Running count</h1>
                        <p>BlackJack running count</p>
                        <button
                          className={"formOkButton"}
                          onClick={() => setMenu(3)}
                        >
                          Count cards!
                        </button>
                      </form>
                    </div>
                  </>
                )}
              </>
            ) : menu === 1 ? (
              <Chat
                setMenu={setMenu}
                setChat={setChat}
                setChatUsername={setChatUsername}
              />
            ) : menu === 2 ? (
              <PrivateChat
                setMenu={setMenu}
                chatId={chat}
                chatUsername={chatUsername}
              />
            ) : menu === 3 ? (
              <RunningCount />
            ) : (
              <>none</>
            )}
          </>
        ) : (
          <div className={"formContainer"}>
            <h1>Not logged in</h1>
            <p>You are not logged in.</p>
            {/*    link*/}
            <Link to={"/Login"} className={"formOkButton"}>
              Login
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
