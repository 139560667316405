import { useSearchParams } from "react-router-dom";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;
export default function VerifyMail() {
  let [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const [submitLoading, setSubmitLoading] = useState(false);

  async function verifyMail() {
    setSubmitLoading(true);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/verify-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, userId }),
        },
      );
      const data: { status: boolean; message: string } = await response.json();
      setSubmitLoading(false);
      toast(data.message, { type: data.status ? "success" : "error" });
    } catch (error) {
      setSubmitLoading(false);
      toast("Error verifying email", { type: "error" });
    }
  }

  return (
    <>
      <div className={"window"}>
        <div className={"formContainer"}>
          <h1>Verify Email</h1>
          <p>Click the button to verify your email</p>
          {submitLoading ? (
            <button
              disabled={true}
              className={"formOkButton"}
              onClick={verifyMail}
            >
              <FontAwesomeIcon icon={faCircleNotch} spin={true} />
            </button>
          ) : (
            <button className={"formOkButton"} onClick={verifyMail}>
              Verify Email
            </button>
          )}
        </div>
      </div>
    </>
  );
}
