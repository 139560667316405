import { toast } from "react-toastify";

const fetchInterceptor = async (url: string, config = {}) => {
  try {
    const response = await fetch(url, config);
    // check if 401
    if (response.status === 401) {
      // redirect to /logout?doLogout=true
      window.location.href = "/logout?doLogout=true";
      toast("Log out and in again!", { type: "error" });
    }
    // const data = await response.json();
    // if (data.message === "Unauthorized") {
    //   data.message += " - Log out and in again!";
    // }
    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};

export default fetchInterceptor;
