import { Link } from "react-router-dom";
import React from "react";
import { toast } from "react-toastify";
import { useAuth } from "../account/AuthContext";
import { ApiEndpoint } from "./apis/apiEndpoint";
import fetchInterceptor from "./fetchInterceptor";
import { useDashboard } from "./context/DashboardContext";

export default function Navbar() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const { menu, setMenu } = useDashboard();
  const apiEndpoint = ApiEndpoint;
  const latestVersion = "1.0.0";

  function checkUrl() {
    if (window.location.href.includes("versemulti.de")) {
      if (window.location.protocol !== "https:") {
        window.location.href = window.location.href.replace("http", "https");
      }
    }
  }

  checkUrl();

  async function PingBackend() {
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "ping",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      );
      const data: { status: boolean; message: string; latestVersion: string } =
        await response.json();
      // if (data.latestVersion != latestVersion) {
      //   window.location.reload();
      // }
      if (!data.status) {
        if (isLoggedIn) {
          window.location.href = "/logout?doLogout=true";
        } else {
          toast(
            "It appears that our services are currently down. Login might not work.",
            { type: "error" },
          );
        }
      }
    } catch (error) {
      console.log("Error pinging backend: ", error);
      if (isLoggedIn) {
        window.location.href = "/logout?doLogout=true";
      } else {
        toast(
          "It appears that our services are currently down. Login might not work.",
          { type: "error" },
        );
      }
    }
  }

  PingBackend();

  function toggleSidebar() {
    const burger: any | null = document.getElementById("burger");
    const navSidebar: any | null = document.getElementById("navSidebar");
    burger.classList.toggle("open");
    navSidebar.classList.toggle("open");
  }

  return (
    <>
      <div className={"navbar"}>
        <div className={"navSectionL"}>
          <Link to={"/"} className={"navLink"}>
            Home
          </Link>
        </div>
        <div className={"navSectionM"}>
          <Link to={"/"} className={"navLink"}>
            Home
          </Link>
        </div>
        {isLoggedIn ? (
          <div className={"navSectionR"}>
            <Link to={"/LogOut"} className={"navLinkBox"}>
              Log out
            </Link>
            <Link
              to={"/Dashboard"}
              className={"navLinkBoxInverted"}
              onClick={() => {
                setMenu(0);
              }}
            >
              Dashboard
            </Link>
          </div>
        ) : (
          <div className={"navSectionR"}>
            <Link to={"/SignUp"} className={"navLinkBox"}>
              Sign up
            </Link>
            <Link to={"/Login"} className={"navLinkBoxInverted"}>
              Login
            </Link>
          </div>
        )}
      </div>
      {/* Mobile Navbar */}
      <div className={"mobile-navbar"}>
        <div className={"mobile-navSectionL"}></div>
        <div className={"mobile-navSectionM"}>
          <Link to={"/"} className={"navLink"}>
            Home
          </Link>
        </div>
        <div className={"mobile-navSectionR"}>
          {/* Show a burger menu */}
          <div className={"burgerMenu"} id={"burger"} onClick={toggleSidebar}>
            <div className={"burgerLine"}></div>
            <div className={"burgerLine"}></div>
            <div className={"burgerLine"}></div>
          </div>
        </div>
        <div className={"navSidebar"} id={"navSidebar"}>
          {isLoggedIn ? (
            <>
              <Link
                to={"/Dashboard"}
                className={"navLinkBoxInverted mobile-linkBox"}
                onClick={() => {
                  setMenu(0);
                  toggleSidebar();
                }}
              >
                Dashboard
              </Link>
              <Link
                to={"/LogOut"}
                className={"navLinkBox mobile-linkBox"}
                onClick={toggleSidebar}
              >
                Log out
              </Link>
            </>
          ) : (
            <>
              <Link
                to={"/SignUp"}
                className={"navLinkBox mobile-linkBox"}
                onClick={toggleSidebar}
              >
                Sign up
              </Link>
              <Link
                to={"/Login"}
                className={"navLinkBoxInverted mobile-linkBox"}
                onClick={toggleSidebar}
              >
                Login
              </Link>
            </>
          )}
        </div>
      </div>
    </>
  );
}
