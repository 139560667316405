export let ForbiddenUserData = [
  "database",
  "table",
  "drop",
  "true",
  "false",
  "null",
  "undefined",
  "NaN",
  "window",
  "document",
  "location",
  "sessionStorage",
  "localStorage",
  "alert",
  "prompt",
  "confirm",
  "fetch",
  "XMLHttpRequest",
  "setTimeout",
  "setInterval",
  "clearTimeout",
  "clearInterval",
  "requestAnimationFrame",
  "webkitRequestAnimationFrame",
  "mozRequestAnimationFrame",
  "oRequestAnimationFrame",
  "msRequestAnimationFrame",
  "postMessage",
  "importScripts",
  "crypto",
  "indexedDB",
  "webkitIndexedDB",
  "mozIndexedDB",
  "msIndexedDB",
  "navigator",
  "screen",
  "history",
  "performance",
  "select",
  "insert",
  "update",
  "delete",
  "create",
  "alter",
  "drop",
  "truncate",
  "exec",
  "execute",
  "prepare",
  "script",
  "iframe",
  "object",
  "embed",
  "applet",
  "link",
  "style",
  "select",
  "optgroup",
  "option",
  "datalist",
  "optgroup",
  "option",
  "datalist",
  "keygen",
  "output",
  "progress",
  "meter",
  "details",
  "summary",
  "command",
  "table",
  "fieldset",
  "legend",
  "label",
  "input",
  "button",
  "select",
  "datalist",
  "optgroup",
  "option",
  "textarea",
  "keygen",
  "output",
  "progress",
  "meter",
  "details",
  "summary",
  "command",
  "script",
  "noscript",
  "template",
  "slot",
  "caption",
  "colgroup",
  "tbody",
  "thead",
  "tfoot",
  "strong",
];
