import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/elements/Home";
import Navbar from "./components/elements/shared/navbar";
import "./components/styles/main.css";
import Footer from "./components/elements/shared/footer";
import Signup from "./components/elements/account/signup";
import Login from "./components/elements/account/login";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyMail from "./components/elements/account/verifyMail";
import {
  AuthProvider,
  useAuth,
} from "./components/elements/account/AuthContext";
import Dashboard from "./components/elements/dashboard/dashboard";
import LogOut from "./components/elements/account/logout";
import PrivacyPolicy from "./components/elements/footersubs/privacypolicy";
import ForgotPassword from "./components/elements/account/forgotPassword";
import ResetPassword from "./components/elements/account/resetPassword";

import { onBackgroundMessage } from "firebase/messaging/sw";
import { ApiEndpoint } from "./components/elements/shared/apis/apiEndpoint";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import NotFound from "./components/elements/notFound";
import PushNotifys from "./components/elements/learnmore/pushNotifys";
import GettingStarted from "./components/elements/learnmore/gettingStarted";
import { DashboardProvider } from "./components/elements/shared/context/DashboardContext";

const apiEndpoint = ApiEndpoint;

function App() {
  const [cookieConsent, setCookieConsent] = useState(false);

  useEffect(() => {
    setCookieConsent(getCookieConsentValue() === "true");
  }, []);

  const handleAccept = async () => {
    setCookieConsent(true);
  };

  return (
    <Router>
      <AuthProvider>
        <DashboardProvider>
          <ToastContainer autoClose={2000} />
          {!cookieConsent && (
            <CookieConsent
              location="bottom"
              buttonText="Ich stimme zu"
              cookieName="userConsent"
              style={{
                background: "#2B373B",
                position: "fixed",
                width: "100%",
              }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              onAccept={handleAccept}
              overlay
            >
              Diese Website verwendet Cookies, um die Benutzererfahrung zu
              ermöglichen.{" "}
            </CookieConsent>
          )}
          <>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/LogOut" element={<LogOut />} />
              <Route path="/SignUp" element={<Signup />} />
              <Route path="/verify-email" element={<VerifyMail />} />
              <Route path={"/Dashboard"} element={<Dashboard menuNum={0} />} />
              <Route path={"/PrivacyPolicy"} element={<PrivacyPolicy />} />
              <Route path={"/ForgotPassword"} element={<ForgotPassword />} />
              <Route path={"/reset-password"} element={<ResetPassword />} />
              <Route
                path={"/learnmore-pushnotifications"}
                element={<PushNotifys />}
              />
              <Route path={"/learnmore"} element={<GettingStarted />} />
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
            <Footer />
          </>
        </DashboardProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
