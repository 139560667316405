import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;

export default function LogOut() {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const [logoutLoading, setLogoutLoading] = useState(false);
  // get ?doLogout from url
  const urlParams = new URLSearchParams(window.location.search);
  const doLogout = urlParams.get("doLogout");
  if (doLogout === "true") {
    logOut();
    window.location.href = "/login";
  }

  async function logOut() {
    console.log("testi");
    setLogoutLoading(true);
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/logout",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      );
      const data: { status: boolean; message: string } = await response.json();
      if (data.status) {
        window.location.href = "/login";
        toast("Logged out", { type: "success" });
      } else {
        toast(data.message, { type: "error" });
      }
      setLogoutLoading(false);
    } catch (error) {
      toast("Error logging out.", { type: "error" });
      setLogoutLoading(false);
    }
  }

  return (
    <>
      <div className={"window"}>
        {isLoggedIn ? (
          // Form with logout button
          <div className={"formContainer"}>
            <h1>Log out</h1>
            <p>Are you sure you want to log out?</p>
            {logoutLoading ? (
              <button className={"formOkButton"} disabled={true}>
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </button>
            ) : (
              <button className={"formOkButton"} onClick={logOut}>
                Log out
              </button>
            )}
          </div>
        ) : (
          <div className={"formContainer"}>
            <h1>Go to login</h1>
            <p>You are not logged in.</p>
            {/*    link*/}
            <Link to={"/Login"} className={"formOkButton"}>
              Login
            </Link>
          </div>
        )}
      </div>
    </>
  );
}
