export default function PrivacyPolicy() {
  return (
    <>
      <div
        className={"window"}
        style={{
          textAlign: "left",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: "80vw",
            margin: "auto",
            overflow: "auto",
          }}
        >
          <h1>Datenschutzerklärung</h1>

          <p>
            "Wir", "uns" und "unser" bezieht sich auf die Betreiber von
            versemulti.de. <br />
            Wir sind eine nicht kommerzielle Website und betreiben diese Website
            aus reinem Interesse an der Technologie und an der weitergabe von
            Wissen. <br /> Wir sind nicht verpflichtet, einen
            Datenschutzbeauftragten zu benennen. <br />
            Wir sind nicht verpflichtet, Informationen über uns zu
            veröffentlichen. <br />
            Alle unten genannten Informationen sind freiwillig und dienen dazu,
            Ihnen ein sicheres Gefühl zu geben, wenn Sie unsere Website nutzen.
          </p>

          <p>
            Wir nehmen den Schutz Ihrer persönlichen Daten ernst und behandeln
            Ihre personenbezogenen Daten vertraulich und entsprechend der
            gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung.
          </p>

          <h2>1. Welche Daten werden erfasst?</h2>

          <p>
            Auf unserer Website erfassen wir nur die notwendigen Daten für die
            Erstellung eines Nutzerkontos: Ihre E-Mail-Adresse, einen von Ihnen
            gewählten Nutzernamen und Ihr Passwort. Diese Daten werden
            ausschließlich für den Login-Prozess und die damit verbundenen
            Funktionen verwendet.
          </p>
          <h2>2. Wie erfassen wir Ihre Daten?</h2>

          <p>
            Ihre Daten werden dadurch erhoben, dass Sie uns diese mitteilen.
            Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Formular
            eingeben. Andere Daten werden automatisch beim Besuch der Website
            durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten
            (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch,
            sobald Sie unsere Website betreten. (Stand 25.03.2024: Derzeit
            erfassen wir keine Daten beim Aufruf unserer Website.)
          </p>
          <h2>3. Wofür nutzen wir Ihre Daten?</h2>

          <p>
            Die Daten werden ausschließlich zur Bereitstellung des
            Login-Bereichs und der damit verbundenen Funktionen, wie das senden
            von Authentifizierungs-E-Mails genutzt. Wir verwenden Ihre Daten
            nicht für Werbezwecke. Wir verkaufen Ihre Daten nicht. Wir verwenden
            Ihre Daten nicht für illegale Zwecke. Wir verwenden Ihre Daten nicht
            für schädliche Zwecke. Wir verwenden diese Daten ausschließlich für
            den Login-Prozess und die damit verbundenen Funktionen.
          </p>

          <h2>4. Welche Rechte haben Sie bezüglich Ihrer Daten?</h2>

          <p>
            Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung
            oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im
            Impressum angegebenen Adresse an uns wenden (support@versemulti.de).
          </p>

          <h2>5. Verwendung von Nodemailer und IONOS</h2>
          <p>
            Für den Versand von Authentifizierungs-E-Mails nutzen wir
            Nodemailer, ein Modul für Node.js, das E-Mails über unseren
            E-Mail-Postausgangs-Server bei IONOS (https://www.ionos.at/) sendet.
            Ihre E-Mail-Adresse wird ausschließlich für den Zweck verwendet,
            Ihnen diese E-Mails zu senden. Wir ergreifen geeignete
            Sicherheitsmaßnahmen, um Ihre Daten während des Versandprozesses zu
            schützen. Ihre Daten werden nur solange gespeichert, wie es für die
            Bereitstellung des Dienstes erforderlich ist. Das bedeutet
            selbstverständlich nicht, dass ihre E-Mail Adresse nach dem Versand
            der Authentifizierungs-E-Mail gelöscht wird. Ihre E-Mail Adresse
            wird so lange gespeichert, wie Ihr Account besteht, damit Sie sich
            jederzeit auch mithilfe ihrer E-Mail Adresse einloggen können und um
            Ihnen die Möglichkeit zu geben, sich ein neues Passwort zusenden zu
            lassen. Ihre E-Mail Adresse wird nicht für Werbezwecke verwendet.
          </p>

          <h2>6. Verwendete Sicherheitsmaßnahmen</h2>
          <p>
            Wir setzen geeignete technische und organisatorische Maßnahmen ein,
            um Ihre Daten vor Verlust, Zerstörung, Manipulation und
            unberechtigtem Zugriff zu schützen. Unsere Sicherheitsmaßnahmen
            werden entsprechend der technologischen Entwicklung fortlaufend
            verbessert.
          </p>
          <h3>6.1 Verschlüsselung des Passworts</h3>
          <p>
            Ihr Passwort wird verschlüsselt gespeichert. Das bedeutet, dass Ihr
            Passwort nicht in Klartext in unserer Datenbank gespeichert wird.
            Wir verwenden ein sicheres Hash-Verfahren, um Ihr Passwort zu
            verschlüsseln. Das bedeutet, dass wir Ihr Passwort nicht
            entschlüsseln können und es auch nicht im Klartext in unserer
            Datenbank gespeichert ist.
          </p>
          <h3>6.2 Verschlüsselung der E-Mail-Adresse</h3>
          <p>
            Ihre E-Mail-Adresse wird verschlüsselt gespeichert. Das bedeutet,
            dass Ihre E-Mail-Adresse nicht in Klartext in unserer Datenbank
            gespeichert wird. Wir verwenden ein sicheres Hash-Verfahren, um Ihre
            E-Mail-Adresse zu verschlüsseln.
          </p>
          <h3>6.3 Verschlüsselung des Nutzernamens</h3>
          <p>
            Ihr Nutzername wird verschlüsselt gespeichert. Das bedeutet, dass
            Ihr Nutzername nicht in Klartext in unserer Datenbank gespeichert
            wird. Wir verwenden ein sicheres Hash-Verfahren, um Ihren
            Nutzernamen zu verschlüsseln.
          </p>
          <h3>6.4 Extrem sichere Nachrichten</h3>
          <p>
            Ihre Nachrichten werden verschlüsselt gespeichert. Das bedeutet,
            dass Ihre Nachrichten nicht in Klartext in unserer Datenbank
            gespeichert werden. Die Nachrichten werden dort verschlüsselt
            gespeichert. Wenn du eine Nachricht sendest, wird diese bei dir
            lokal mit euren chat-secret verschlüsselt und dann an unseren Server
            gesendet. Sie kann also nicht vom Server gelesen werden. Dort wird
            die Nachricht trotzdem mit einem anderen Schlüssel verschlüsselt und
            in der Datenbank gespeichert. Wenn du eine Nachricht empfängst, wird
            die Nachricht aus der Datenbank geholt und zunächst mit einem
            sicheren Schlüssel entschlüsselt. Trotzdem ist die Nachricht noch
            nicht lesbar. Die Nachricht wird dann erst bei dir auf deinem Gerät
            mit deinem chat-secret entschlüsselt und dir angezeigt. Das
            bedeutet, dass niemand außer dir und dem Empfänger die Nachricht
            lesen kann. Nicht einmal wir können die Nachricht lesen. Das bietet
            euch ein extrem hohes Maß an Sicherheit und Privatsphäre.
          </p>

          <h2>7. Warum wir sicherer sind als andere Websites</h2>
          <p>
            Wir speichern Ihre Daten nicht in Klartext. Das bedeutet, dass Ihre
            Daten nicht unverschlüsselt in unserer Datenbank gespeichert sind.
            Wir verwenden sichere Hash-Verfahren, um Ihre Daten zu verschlüsseln
            und zu schützen. Das bedeutet, dass Ihre Daten auch dann sicher
            sind, wenn unsere Datenbank gehackt wird. Ihre Daten sind sicher und
            können ohne weiteres nicht entschlüsselt werden.
            <br />
            Grundsätzlich ist es nicht notwendig, dass wir Ihre Personendaten in
            einem Verschlüsselten Format speichern. Wir könnten Ihre
            Personendaten auch unverschlüsselt speichern und trotzdem eine
            gleichwertige Sicherheit gewährleisten. Wir haben uns jedoch dazu
            entschieden, jegliche Daten zu verschlüsseln, um Ihnen ein
            zusätzliches Sicherheitsgefühl zu geben. Wir möchten, dass Sie sich
            sicher fühlen, wenn Sie unsere Website nutzen. Wir möchten, dass Sie
            wissen, dass Ihre Daten sicher sind und dass Sie sich keine Sorgen
            machen müssen, dass Ihre Daten von Dritten eingesehen werden
            könnten.
          </p>
          <h3>7.1 Sichere Verbindung zum Webserver</h3>
          <p>
            Unsere Website verwendet eine sichere Verbindung zum Webserver.
            (Wenn Sie mit unserem "https" Server verbunden sind.) Das bedeutet,
            dass Ihre Daten verschlüsselt übertragen werden. Ihre Daten sind
            sicher und können nicht von Dritten oder Angreifern eingesehen
            werden. Wir verwenden ein sicheres SSL-Zertifikat, um Ihre Daten zu
            verschlüsseln. Es liegt in Ihrem Interesse, bei jedem Besuch unserer
            Website darauf zu achten, dass die Verbindung zum Webserver sicher
            ist. Sie können dies anhand des Schlosssymbols in der Adressleiste
            Ihres Browsers erkennen. Stellen Sie sicher, dass das Schlosssymbol
            angezeigt wird, bevor Sie Ihre Daten eingeben. Wenn das
            Schlosssymbol nicht angezeigt wird, sollten Sie die Website
            verlassen und nicht Ihre Daten eingeben. Versichern Sie sich bitte
            bei jedem Besuch unserer Website, dass die URL mit "https://"
            beginnt. Das "s" steht für "sicher". Stellen Sie sicher, dass die
            URL exakt mit folgender URL übereinstimmt: "https://versemulti.de"
          </p>
          <h3>7.2 Sichere Anfragen an unser Backend</h3>
          <p>
            Unsere Website verwendet sichere Anfragen an unser Backend. Das
            bedeutet, dass Ihre Daten verschlüsselt an unser Backend gesendet
            werden. Ihre Daten sind sicher und können nicht von Dritten
            eingesehen werden. Es liegt auch in unserem Interesse, dass Ihre
            Daten sicher sind und es nicht-Autorisierten nicht möglich ist, Ihre
            Daten einzusehen. Unser Backend ist extrem sicher und es ist nahezu
            unmöglich, dass Ihre Daten von Dritten eingesehen werden können.
            Unser Backend antwortet nur auf Anfragen, die von unserer Website
            kommen. Für anfragen, die Personenbezogenen Daten enthalten, wird
            eine Authentifizierung benötigt. Unser Backend ist so konfiguriert,
            dass diese Authentifizierung nur durch ein erfolgreiches Einloggen
            auf unserer Website möglich ist. Unser Backend Validiert ihre
            Browsersitzung und ermöglicht es ihnen für 60 Minuten, Anfragen zu
            stellen. Nach 60 Minuten wird die Authentifizierung zurückgesetzt
            und sie müssen sich erneut einloggen, um Anfragen an unser Backend
            stellen zu können.
          </p>
          <h3>7.3 Login Validierung</h3>
          <p>
            Die Authentifizierung passiert nur Serverseitig und über einen
            http-only Cookie. Das bedeutet, dass ihre Authentifizierung nicht
            von Dritten eingesehen werden kann. Ihr Browser sendet bei jeder
            Anfrage an unser Backend den http-only Cookie mit, um zu beweisen,
            dass Sie eingeloggt sind. JavaScript oder schädliche
            Browsererweiterungen können nicht auf den http-only Cookie
            zugreifen. Es werden keine personenbezogenen Daten in den http-only
            Cookie gespeichert. Der http-only Cookie enthält nur eine
            Authentifizierungs-ID, die auf unserem Backend-Server gespeichert
            ist. Der http-only Cookie wird für 60 Minuten gespeichert. Nach 60
            Minuten wird der http-only Cookie gelöscht und Sie müssen sich
            erneut einloggen, um Anfragen an unser Backend stellen zu können.
          </p>
          <h2>8. Allgemeine Hinweise und Pflichtinformationen</h2>
          <p>
            Als nicht kommerzielle Website sind wir nicht verpflichtet, einen
            Datenschutzbeauftragten zu benennen. Wir sind nicht verpflichtet,
            Informationen über uns zu veröffentlichen. Alle oben genannten
            Informationen sind freiwillig und dienen dazu, Ihnen ein sicheres
            Gefühl zu geben, wenn Sie unsere Website nutzen. Wichtig: Es ist
            niemals, in keinem Fall möglich Daten zu 100% sicher im Internet zu
            übertragen. Wir tun unser Bestes, um Ihre Daten sicher zu übertragen
            und zu speichern, aber wir können nicht garantieren, dass Ihre Daten
            zu 100% sicher sind.
            <br />
            Wenn also von 100% Sicherheit die Rede ist, ist damit gemeint, dass
            wir versuchen, die höchstmögliche Sicherheit zu gewährleisten, die
            es uns technisch möglich ist. Trotzdem können wir nicht garantieren,
            dass wir sofort auf alle Sicherheitslücken reagieren können. Falls
            Sie eine Sicherheitslücke finden, bitten wir Sie, uns umgehend zu
            informieren. Wir werden uns bemühen, die Sicherheitslücke so schnell
            wie möglich zu schließen.
            <br />
            Wir können nicht garantieren, dass Ihre Daten nicht von Dritten
            eingesehen werden können. Wir können nicht garantieren, dass Ihre
            Daten nicht gehackt werden können. Wir können nicht garantieren,
            dass Ihre Daten nicht verloren gehen können. Wir können nicht
            garantieren, dass Ihre Daten nicht manipuliert werden können. Wir
            können nicht garantieren, dass Ihre Daten nicht gestohlen werden
            können. Wir können nicht garantieren, dass Ihre Daten nicht
            missbraucht werden können. Wir können nicht garantieren, dass Ihre
            Daten nicht verändert werden können. Wir können nicht garantieren,
            dass Ihre Daten nicht gelöscht werden können. Wir können nicht
            garantieren, dass Ihre Daten nicht beschädigt werden können. Wir
            können nicht garantieren, dass Ihre Daten nicht kopiert werden
            können. Wir können nicht garantieren, dass Ihre Daten nicht
            weitergegeben werden können. Wir können nicht garantieren, dass Ihre
            Daten nicht verkauft werden können. Wir können nicht garantieren,
            dass Ihre Daten nicht für illegale Zwecke verwendet werden können.
            Wir können nicht garantieren, dass Ihre Daten nicht für schädliche
            Zwecke verwendet werden können. Wir können nicht garantieren, dass
            Ihre Daten nicht für Betrug verwendet werden können. Wir können
            nicht garantieren, dass Ihre Daten nicht für Identitätsdiebstahl
            verwendet werden können. Wir können nicht garantieren, dass Ihre
            Daten nicht für Spam verwendet werden können. Wir können nicht
            garantieren, dass Ihre Daten nicht für Phishing verwendet werden
            können. Wir können nicht garantieren, dass Ihre Daten nicht für
            Viren verwendet werden können. Wir können nicht garantieren, dass
            Ihre Daten nicht für Malware verwendet werden können. Wir können
            nicht garantieren, dass Ihre Daten nicht für Ransomware verwendet
            werden können. Wir können nicht garantieren, dass Ihre Daten nicht
            für Erpressung verwendet werden können. ABER: Ihre Daten sind bei
            uns ziemlich wahrscheinlich sicherer als auf anderen Websites.{" "}
            <br />
            <br />
            <br /> Gern geschehen.
            <br />
            <br />
            Das war's. Das war unsere Datenschutzerklärung. Wir hoffen, dass Sie
            sich jetzt sicher fühlen, wenn Sie unsere Website nutzen. Danke für
            Ihr Vertrauen. Wir schätzen es sehr. Wenn Sie Fragen haben, können
            Sie uns gerne unter support@versemulti.de kontaktieren. Wir helfen
            Ihnen gerne weiter.
          </p>

          <h1>Privacy Policy</h1>

          <p>
            "We," "us," and "our" refer to the operators of versemulti.de.{" "}
            <br />
            We are a non-commercial website and operate this site purely out of
            an interest in technology and sharing knowledge. <br /> We are not
            required to appoint a data protection officer. <br />
            We are not obligated to publish information about ourselves. <br />
            All information provided below is voluntary and is intended to give
            you peace of mind when using our website.
          </p>

          <p>
            We take the protection of your personal data seriously and treat
            your personal information confidentially and in accordance with the
            legal data protection regulations and this privacy policy.
          </p>

          <h2>1. What data do we collect?</h2>

          <p>
            On our website, we only collect the necessary data for creating a
            user account: your email address, a username you choose, and your
            password. These data are used exclusively for the login process and
            the associated functions.
          </p>
          <h2>2. How do we collect your data?</h2>

          <p>
            Your data are collected by you providing them to us. This might be
            data that you enter into a form. Other data are automatically
            collected by our IT systems when you visit the website. These are
            primarily technical data (e.g., internet browser, operating system,
            or time of the page request). The collection of these data occurs
            automatically as soon as you enter our website. (As of 25.03.2024:
            Currently, we do not collect any data upon visiting our website.)
          </p>
          <h2>3. What do we use your data for?</h2>

          <p>
            The data are used exclusively to provide the login area and
            associated functions, such as sending authentication emails. We do
            not use your data for advertising purposes. We do not sell your
            data. We do not use your data for illegal purposes. We do not use
            your data for harmful purposes. We use these data exclusively for
            the login process and the associated functions.
          </p>

          <h2>4. What rights do you have regarding your data?</h2>

          <p>
            You have the right at any time to receive information about the
            origin, recipient, and purpose of your stored personal data free of
            charge. You also have the right to request the correction, blocking,
            or deletion of these data. For this purpose, as well as for further
            questions on the topic of data protection, you can contact us at any
            time at the address given in the imprint (support@versemulti.de).
          </p>

          <h2>5. Use of Nodemailer and IONOS</h2>
          <p>
            For sending authentication emails, we use Nodemailer, a Node.js
            module that sends emails through our email outgoing server at IONOS
            (https://www.ionos.at/). Your email address is used exclusively for
            the purpose of sending you these emails. We take appropriate
            security measures to protect your data during the sending process.
            Your data are stored only as long as necessary for the provision of
            the service. This, of course, does not mean that your email address
            is deleted after sending the authentication email. Your email
            address is stored as long as your account exists, so you can log in
            at any time using your email address and have the option to request
            a new password. Your email address is not used for advertising
            purposes.
          </p>

          <h2>6. Security Measures Used</h2>
          <p>
            We implement appropriate technical and organizational measures to
            protect your data against loss, destruction, manipulation, and
            unauthorized access. Our security measures are continuously improved
            in line with technological developments.
          </p>
          <h3>6.1 Password Encryption</h3>
          <p>
            Your password is stored in encrypted form. This means that your
            password is not stored in plain text in our database. We use a
            secure hashing process to encrypt your password. This means we
            cannot decrypt your password, and it is not stored in plain text in
            our database.
          </p>
          <h3>6.2 Email Address Encryption</h3>
          <p>
            Your email address is stored in encrypted form. This means that your
            email address is not stored in plain text in our database. We use a
            secure hashing process to encrypt your email address.
          </p>
          <h3>6.3 Username Encryption</h3>
          <p>
            Your username is stored in encrypted form. This means that your
            username is not stored in plain text in our database. We use a
            secure hashing process to encrypt your username.
          </p>
          <h3>6.4 Extremely Secure Messages</h3>
          <p>
            Your messages are stored in encrypted form. This means that your
            messages are not stored in plain text in our database. The messages
            are stored in encrypted form. When you send a message, it is
            encrypted with your chat secret locally and then sent to our server.
            It cannot be read by the server. The message is encrypted with
            another key and stored in the database. When you receive a message,
            the message is retrieved from the database and decrypted with a
            secure key. However, the message is still not readable. The message
            is then decrypted with your chat secret on your device and displayed
            to you. This means that no one except you and the recipient can read
            the message. Not even we can read the message. This provides you
            with an extremely high level of security and privacy.
          </p>

          <h2>7. Why We Are Safer Than Other Websites</h2>
          <p>
            {" "}
            We do not store your data in plain text. This means that your data
            are not unencrypted in our database. We use secure hashing processes
            to encrypt and protect your data. This means your data are still
            safe even if our database is hacked. Your data are secure and cannot
            be easily decrypted.
            <br />
            Fundamentally, it is not necessary for us to store your personal
            data in an encrypted format. We could store your personal data
            unencrypted and still ensure equivalent security. However, we have
            chosen to encrypt all data to provide you with an additional sense
            of security. We want you to feel safe when using our website. We
            want you to know that your data are secure and that you do not need
            to worry about your data being viewed by third parties.
          </p>
          <h3>7.1 Secure Connection to the Web Server</h3>
          <p>
            Our website uses a secure connection to the web server. (When you
            are connected to our "https" server.) This means that your data are
            transmitted encrypted. Your data are secure and cannot be viewed by
            third parties or attackers. We use a secure SSL certificate to
            encrypt your data. It is in your interest to ensure that the
            connection to the web server is secure each time you visit our
            website. You can recognize this by the lock icon in the address bar
            of your browser. Ensure that the lock icon is displayed before
            entering your data. If the lock icon is not displayed, you should
            leave the website and not enter your data. Please ensure each time
            you visit our website that the URL starts with "https://". The "s"
            stands for "secure". Make sure the URL exactly matches the following
            URL: "https://versemulti.de"
          </p>
          <h3>7.2 Secure Requests to Our Backend</h3>
          <p>
            Our website uses secure requests to our backend. This means that
            your data are sent encrypted to our backend. Your data are secure
            and cannot be viewed by third parties. It is also in our interest
            that your data are secure and it is not possible for unauthorized
            individuals to view your data. Our backend is extremely secure, and
            it is nearly impossible for your data to be viewed by third parties.
            Our backend only responds to requests that come from our website.
            For requests containing personal data, authentication is required.
            Our backend is configured to allow this authentication only through
            successful login on our website. Our backend validates your browser
            session and allows you to make requests for 60 minutes. After 60
            minutes, the authentication is reset, and you need to log in again
            to make requests to our backend.
          </p>
          <h3>7.3 Login Validation</h3>
          <p>
            Authentication occurs only server-side via an http-only cookie. This
            means your authentication cannot be viewed by third parties. Your
            browser sends the http-only cookie with every request to our backend
            to prove you are logged in. JavaScript or malicious browser
            extensions cannot access the http-only cookie. No personal data are
            stored in the http-only cookie. The http-only cookie contains only
            an authentication ID, which is stored on our backend server. The
            http-only cookie is stored for 60 minutes. After 60 minutes, the
            http-only cookie is deleted, and you need to log in again to make
            requests to our backend.
          </p>
          <h2>8. General Notes and Mandatory Information</h2>
          <p>
            As a non-commercial website, we are not required to appoint a data
            protection officer. We are not obligated to publish information
            about ourselves. All the information provided above is voluntary and
            is intended to give you a sense of security when using our website.
            Important: It is never possible to transmit data over the Internet
            with 100% security. We do our best to transmit and store your data
            securely, but we cannot guarantee that your data are 100% secure.
            <br />
            When we talk about 100% security, we mean that we strive to provide
            the highest possible level of security that is technically feasible
            for us. However, we cannot guarantee that we can respond immediately
            to all security vulnerabilities. If you find a security
            vulnerability, please inform us immediately. We will endeavor to
            close the security gap as quickly as possible.
            <br />
            We cannot guarantee that your data cannot be viewed by third
            parties. We cannot guarantee that your data cannot be hacked. We
            cannot guarantee that your data cannot be lost. We cannot guarantee
            that your data cannot be manipulated. We cannot guarantee that your
            data cannot be stolen. We cannot guarantee that your data cannot be
            misused. We cannot guarantee that your data cannot be altered. We
            cannot guarantee that your data cannot be deleted. We cannot
            guarantee that your data cannot be damaged. We cannot guarantee that
            your data cannot be copied. We cannot guarantee that your data
            cannot be disclosed. We cannot guarantee that your data cannot be
            sold. We cannot guarantee that your data cannot be used for illegal
            purposes. We cannot guarantee that your data cannot be used for
            harmful purposes. We cannot guarantee that your data cannot be used
            for fraud. We cannot guarantee that your data cannot be used for
            identity theft. We cannot guarantee that your data cannot be used
            for spam. We cannot guarantee that your data cannot be used for
            phishing. We cannot guarantee that your data cannot be used for
            viruses. We cannot guarantee that your data cannot be used for
            malware. We cannot guarantee that your data cannot be used for
            ransomware. We cannot guarantee that your data cannot be used for
            extortion. BUT: Your data are most likely safer with us than on
            other websites.
            <br />
            <br />
            <br /> You're welcome.
            <br />
            <br />
            That's it. That was our privacy policy. We hope you now feel secure
            when using our website. Thank you for your trust. We appreciate it
            greatly. If you have any questions, please feel free to contact us
            at support@versemulti.de. We're happy to help.
          </p>
        </div>
      </div>
    </>
  );
}
