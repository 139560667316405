export class ApiEndpoint {
  static getApiEndpoint() {
    // return "http://localhost:3003/app-comm/";
    return "https://comm.versemulti.de/app-comm/";
    //
    //
    // return "https://vem-backend.adaptable.app/app-comm/";
  }
  // static getApiEndpoint() {
  //   return "https://vem-backend.adaptable.app/app-comm/";
  // }
  // static getApiEndpoint() {
  //   return "localhost:3000/";
  // }
}
