import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className={"footer"}>
      <Link className={"footerLink"} to={"/"}>
        About this page
      </Link>
      <Link className={"footerLink"} to={"/"}>
        About me
      </Link>
      <Link className={"footerLink"} to={"/"}>
        Terms and Conditions
      </Link>
      <Link className={"footerLink"} to={"/PrivacyPolicy"}>
        Privacy policy
      </Link>
    </div>
  );
}
