import React, { useState } from "react";
import { inspect } from "util";

export default function RunningCount() {
  const [numbers, setNumbers] = useState<(number | string)[]>([]);
  const [runningCount, setRunningCount] = useState(0);
  const [cardCounts, setCardCounts] = useState<{ [key: string]: number }>({
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    K: 0,
    J: 0,
    Q: 0,
    A: 0,
  });
  const [decks, setDecks] = useState(1);

  const handleClick = (value: number | string) => {
    setNumbers([value, ...numbers]);
    updateRunningCount(value);
    updateCardCount(value);
    //   vibrate phone (soft for small, medium for 7 8 9 and hard for 10)
    if (navigator.vibrate) {
      if (value === 7 || value === 8 || value === 9) {
        navigator.vibrate([
          10, 25, 10, 24, 10, 23, 10, 18, 10, 9, 10, 9, 10, 18, 10, 23, 10, 24,
          10,
        ]);
      } else if (value < 7) {
        navigator.vibrate([
          5, 25, 5, 25, 5, 23, 5, 21, 5, 16, 5, 8, 5, 8, 5, 16, 5, 21, 5, 23, 5,
          25, 5,
        ]);
      } else {
        navigator.vibrate([
          20, 25, 20, 24, 20, 20, 20, 11, 20, 11, 20, 20, 20, 24, 20,
        ]);
      }
    }
  };

  const updateRunningCount = (value: number | string) => {
    let count = 0;
    if (typeof value === "number") {
      if (value >= 2 && value <= 6) {
        count = 1;
      } else if (value === 10) {
        count = -1;
      }
    } else {
      count = -1;
    }
    setRunningCount(runningCount + count);
  };

  const updateCardCount = (value: number | string) => {
    setCardCounts({
      ...cardCounts,
      [value]: cardCounts[value] + 1,
    });
  };

  const handleDeleteLast = () => {
    const lastValue = numbers.shift();
    setNumbers([...numbers]);
    if (lastValue !== undefined) {
      reverseRunningCount(lastValue);
      reverseCardCount(lastValue);
    }
  };

  const reverseRunningCount = (value: number | string) => {
    let count = 0;
    if (typeof value === "number") {
      if (value >= 2 && value <= 6) {
        count = -1;
      } else if (value === 10) {
        count = 1;
      }
    } else {
      count = 1;
    }
    setRunningCount(runningCount + count);
  };

  const reverseCardCount = (value: number | string) => {
    setCardCounts({
      ...cardCounts,
      [value]: cardCounts[value] - 1,
    });
  };

  const getColorForCardValue = (card: number | string) => {
    const maxCount = 4 * decks; // Each card appears 4 times per deck
    return cardCounts[card] >= maxCount ? "red" : "gray";
  };

  const getTrueCount = () => {
    const decksLeft = decks - numbers.length / 52;
    return (runningCount / decksLeft).toFixed(2);
  };

  const getColorForCount = (count: number) => {
    if (count > 0) return "green";
    if (count < 0) return "red";
    return "black";
  };

  const cardValues = [2, 3, 4, 5, 6, 7, 8, 9, 10, "K", "J", "Q", "A"];

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          type="text"
          value={numbers.join(" ")}
          readOnly
          style={{
            marginBottom: "20px",
            textAlign: "center",
            padding: "10px",
            fontSize: "18px",
            width: "80%",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {cardValues.slice(0, 5).map((value) => (
              <span
                key={value}
                style={{
                  fontSize: "18px",
                  margin: "5px",
                }}
              >
                <span style={{ color: "white" }}>{value}:</span>{" "}
                <span style={{ color: getColorForCardValue(value) }}>
                  {cardCounts[value]}
                </span>
              </span>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {cardValues.slice(5, 10).map((value) => (
              <span
                key={value}
                style={{
                  fontSize: "18px",
                  margin: "5px",
                }}
              >
                <span style={{ color: "white" }}>{value}:</span>{" "}
                <span style={{ color: getColorForCardValue(value) }}>
                  {cardCounts[value]}
                </span>
              </span>
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            {cardValues.slice(10).map((value) => (
              <span
                key={value}
                style={{
                  fontSize: "18px",
                  margin: "5px",
                }}
              >
                <span style={{ color: "white" }}>{value}:</span>{" "}
                <span style={{ color: getColorForCardValue(value) }}>
                  {cardCounts[value]}
                </span>
              </span>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "10px",
            marginBottom: "20px",
          }}
        >
          <div style={{ visibility: "hidden" }}>1</div> {/* Invisible Tile */}
          {cardValues.map((value) => (
            <button
              key={value}
              onClick={() => handleClick(value)}
              className={"cardButton"}
            >
              {value}
            </button>
          ))}
        </div>
        <label
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: getColorForCount(runningCount),
          }}
        >
          Running Count: {runningCount}
        </label>
        <label
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: getColorForCount(parseFloat(getTrueCount())),
          }}
        >
          True Count: {getTrueCount()}
        </label>
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50vw",
              alignItems: "center",
            }}
          >
            <span style={{ color: "white" }}>Decks:</span>
            <input
              type="number"
              value={decks}
              onChange={(e) => setDecks(Number(e.target.value))}
              style={{
                width: "50px",
                padding: "10px",
                fontSize: "18px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "0px",
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
              min="1"
              step="1"
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "50vw",
            }}
          >
            <button
              onClick={handleDeleteLast}
              style={{
                padding: "10px 20px",
                fontSize: "18px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                backgroundColor: "#f0f0f0",
                width: "150px",
              }}
            >
              Delete Last Number
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
