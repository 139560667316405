export default function PushNotifys() {
  return (
    <>
      <div className={"window"} style={{ overflow: "auto" }}>
        <div className={"learnmoreCont"}>
          <h1>Push notifications</h1>
          <p>
            Push notifications are messages that pop up on a user's device even
            when the user is not using the app. They are a great way to keep
            users engaged and informed about important events. <br />
            <br />
          </p>
          <h3>What if my browser does not support push notifications?</h3>
          <p>
            If your browser does not support push notifications, you will not be
            able to receive them. This means you will not be able to receive
            important updates and messages when you are not using the app.
            Usually, you can still use this app without push notifications, but
            some features may not work as expected. <br />
            <br />
          </p>
          <h3>How do I enable push notifications?</h3>
          <p>
            To enable push notifications, you will need to use a browser that
            supports them. Most modern browsers support push notifications, but
            some older browsers do not. <br />
            <br />
            In general, when you enter the dashboard, the app checks if your
            browser supports push notifications. If it does, you will see a
            prompt asking if you want to enable them. If you accept, you will
            start receiving push notifications. <br />
            <br />
            If you are using an older browser, you may need to update it to
            receive push notifications. Try to first update your operating
            system and then your browser. <br />
            <br />
            If you know you have the latest version of your browser and still
            the message "Push notifications are not supported" appears, you may
            will not be able to receive those messages <br />
            <br />
            <br />
            <h3>How do I disable push notifications?</h3>
            <p>
              If you no longer want to receive push notifications, you can
              disable them in your browser settings. The exact steps to disable
              push notifications depend on your browser and operating system.
              You will soon have the option to disable them in your account
              settings. For now, you will have to manually look up how to
              disable them.
              <br />
              <br />
              In general, you can disable push notifications by going to your
              browser settings and looking for the push notifications section.
              From there, you can turn off push notifications for this app.{" "}
              <br />
              <br />
            </p>
          </p>
        </div>
      </div>
    </>
  );
}
