import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div className={"window"}>
        <div className={"learnmoreCont"} style={{ height: "80vh" }}>
          <h1>404 - Page not found</h1>
          <p>
            The page you are looking for does not exist. Please check the URL
            and try again.
          </p>
          <Link to={"/"} className={"formOkButton"}>
            Go to Home
          </Link>
        </div>
      </div>
    </>
  );
}
