import { useAuth } from "./AuthContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faKey,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;

export default function ForgotPassword() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    let interval: any;
    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [isButtonDisabled]);

  useEffect(() => {
    if (timer === 0) {
      setIsButtonDisabled(false);
      setTimer(60); // Reset timer
    }
  }, [timer]);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setSubmitLoading(true);
    setIsButtonDisabled(true);
    const data = {
      username: event.target.username.value,
    };
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/forgot-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        },
      );
      const result = await response.json();
      if (result.status) {
        toast("Password reset link sent successfully", { type: "success" });
      } else {
        toast("Error sending password reset link", { type: "error" });
      }
    } catch (error) {
      toast("Error sending password reset link", { type: "error" });
      setTimer(10);
    }
    setSubmitLoading(false);
  }

  return (
    <>
      <div className={"window"}>
        {isLoggedIn ? (
          <form className={"formContainer"}>
            <h1>Forgot Password</h1>
            <p>You are logged in.</p>
            <p>Change your password in settings.</p>
          </form>
        ) : (
          <form className={"formContainer"} onSubmit={handleSubmit}>
            <h1>Reset Password</h1>
            <label className={"formLabel"}>
              <div className={"rollerDiv"}>
                <FontAwesomeIcon icon={faUserTie} />
                <div className={"rollerString"}>
                  <span className={"rollerText"} style={{ color: "white" }}>
                    Username:
                  </span>
                  <span className={"rollerText"} style={{ color: "white" }}>
                    E-Mail:
                  </span>
                </div>
                <span
                  className={"rollerNext"}
                  style={{ color: "white" }}
                ></span>
                <input
                  className={"formTextInput rollerInput"}
                  style={{ zIndex: 2 }}
                  type="text"
                  name="username"
                />
              </div>
            </label>
            {submitLoading ? (
              <button disabled={true} className={"formOkButton"}>
                <FontAwesomeIcon icon={faCircleNotch} spin={true} />
              </button>
            ) : (
              <>
                {isButtonDisabled ? (
                  <input
                    className={"formOkButton"}
                    type={"submit"}
                    disabled={true}
                    value={`Resend in ${timer}s`}
                  />
                ) : (
                  <input
                    className={"formOkButton"}
                    type={"submit"}
                    value={"Send Reset Link"}
                  />
                )}
              </>
            )}
          </form>
        )}
      </div>
    </>
  );
}
