import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toast, ToastContainer } from "react-toastify";
import {
  faClipboardCheck,
  faEnvelope,
  faKey,
  faUserTie,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import { UserSignUp } from "../shared/interfaces/userSignUp";
import { ForbiddenUserData } from "../shared/lists/forbiddenUserData";
import { ApiEndpoint } from "../shared/apis/apiEndpoint";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import fetchInterceptor from "../shared/fetchInterceptor";
const apiEndpoint = ApiEndpoint;
export default function Signup() {
  const [submitLoading, setSubmitLoading] = useState(false);
  let UserSignUp: UserSignUp = {
    email: "",
    username: "",
    password: "",
    confirmpassword: "",
  };

  async function saveSignUpData() {
    setSubmitLoading(true);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "user/signup",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(UserSignUp),
        },
      );
      const data = await response.json();
      setSubmitLoading(false);
      if (data.status === true) {
        toast(
          <div>
            {data.message}
            <br />
            Go to <Link to="/login">Login</Link> to continue
          </div>,
          {
            type: "success",
          },
        );
      } else {
        toast(data.message, { type: "error" });
      }
    } catch (error) {
      setSubmitLoading(false);
      toast("Error signing up user", { type: "error" });
    }
  }

  function checkSignUpData() {
    const email = document.querySelector(
      "input[name='email']",
    ) as HTMLInputElement;
    const username = document.querySelector(
      "input[name='username']",
    ) as HTMLInputElement;
    const password = document.querySelector(
      "input[name='password']",
    ) as HTMLInputElement;
    const confirmpassword = document.querySelector(
      "input[name='confirmpassword']",
    ) as HTMLInputElement;

    if (password.value !== confirmpassword.value) {
      alert("Passwords do not match!");
      return;
    }
    // Password must be at least 8 characters long
    if (password.value.length < 8) {
      alert("Password must be at least 8 characters long!");
      return;
    }

    UserSignUp.email = email.value;
    UserSignUp.username = username.value;
    UserSignUp.password = password.value;
    UserSignUp.confirmpassword = confirmpassword.value;

    //check if any of the data contains a string of the forbiddenUserData list
    const forbiddenUserData = ForbiddenUserData;
    for (let i = 0; i < forbiddenUserData.length; i++) {
      if (
        UserSignUp.email.includes(forbiddenUserData[i]) ||
        UserSignUp.username.includes(forbiddenUserData[i]) ||
        UserSignUp.password.includes(forbiddenUserData[i]) ||
        UserSignUp.confirmpassword.includes(forbiddenUserData[i])
      ) {
        alert("Forbidden data found!");
        return;
      }
    }
    saveSignUpData();
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    checkSignUpData();
  };

  return (
    <>
      <div className={"window"}>
        <form
          className={"formContainer"}
          style={{ height: "70vh" }}
          onSubmit={handleSubmit}
        >
          <h1>Sign up</h1>
          <label className={"formLabel"}>
            <FontAwesomeIcon
              icon={faEnvelope}
              data-tooltip-id={"email"}
              data-tooltip-content={
                "This will be your login email. You will have to confirm your email at the first login."
              }
            />
            <Tooltip id={"email"} place={"right"} />
            E-Mail
            <input
              className={"formTextInput"}
              type="email"
              name="email"
              maxLength={100}
              required={true}
            />
          </label>
          <label className={"formLabel"}>
            <FontAwesomeIcon
              icon={faUserTie}
              data-tooltip-id={"username"}
              data-tooltip-content={
                "This will be your public name. It does not have to be your real name."
              }
            />
            <Tooltip id={"username"} place={"right"} />
            Username
            <input
              className={"formTextInput"}
              type="text"
              name="username"
              maxLength={30}
              required={true}
            />
          </label>
          <label className={"formLabel"}>
            <FontAwesomeIcon
              icon={faKey}
              data-tooltip-id={"password"}
              data-tooltip-content={
                "Password must be at least 8 characters long."
              }
            />
            <Tooltip id={"password"} place={"right"} />
            Password
            <input
              className={"formTextInput"}
              type="password"
              name="password"
              required={true}
            />
          </label>
          <label className={"formLabel"}>
            <FontAwesomeIcon icon={faClipboardCheck} />
            Again...
            <input
              className={"formTextInput"}
              type="password"
              name="confirmpassword"
              required={true}
            />
          </label>
          {/*agree to privacy policy*/}
          <label className={"formLabel"}>
            <a>
              I agree to the{"  "}
              <Link to="/PrivacyPolicy" style={{ color: "gold" }}>
                privacy policy
              </Link>
            </a>
            <input className={"formCheckbox"} type="checkbox" required={true} />
          </label>

          {submitLoading ? (
            <button className="formOkButton" type="submit">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </button>
          ) : (
            <input className={"formOkButton"} type="submit" value="Sign up" />
          )}
        </form>
      </div>
    </>
  );
}
