export default function GettingStarted() {
  return (
    <>
      <div className={"window"} style={{ overflow: "auto" }}>
        <div className={"learnmoreCont"}>
          <h1>Getting Started</h1>
          <h2>First steps</h2>
          <h3>1. Create an account</h3>
          <p>
            To start using this app, you need to create an account. You can do
            this by clicking the "Sign up" button in the menu. You will need to
            provide an valid email address and a password. After you have
            created an account, verify your email address by clicking the link
            sent to your email. <br />
            <br />
            <br />
          </p>
          <h4>Why do we need your email?</h4>
          <p>
            We need a valid email address to verify your account and to send you
            an email in case you forget your password. We will never share your
            email address with third parties. <br />
            <br />
          </p>
          <h3>2. Contact the support, to get your account enabled</h3>
          <p>
            <b style={{ color: "red" }}>
              After you have created an account, you need to contact the support
              to get your account enabled. <br />
            </b>{" "}
            You can do this by sending an email to the support email address.
            The senders email address must be the same as the email address you
            used to create the account. <br />
            <b style={{ color: "yellow" }}>
              If someone recommended you to use this app, you can also ask them
              to contact the administrator to enable your account. This might be
              faster.
            </b>
            <br />
            <br />
            <br />
          </p>
          <h4>Why do we need to enable your account?</h4>
          <p>
            We need to enable your account to make sure you are a real person
            and not a bot. This is to prevent spam and abuse. <br />
            <br />
          </p>
          <h3>3. Log in</h3>
          <p>
            After your account has been enabled, you can log in by clicking the
            "Log in" button in the menu. You will need to either provide your
            email or username and your password. <br />
            <br />
          </p>
          <h3>4. Go to the dashboard</h3>
          <p>
            After you have logged in, go to the dashboard by clicking the
            "Dashboard" button in the menu. When you enter the dashboard, the
            app checks if your browser supports push notifications. If it does,
            you will see a prompt asking if you want to enable them (which you
            should allow). If you accept, you will start receiving push
            notifications. We will not send you any unnecessary notifications.
            This is just for you to get notified when you receive a new chat!
            <br />
            <br />
          </p>
          <h3>5. Enjoy the app</h3>
          <p>
            Now you are ready to start using the app. You can chat with other
            users by creating private chats. If you have any questions or need
            help, feel free to contact the support. <br />
            <br />
          </p>
        </div>
      </div>
    </>
  );
}
