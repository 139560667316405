import React, { createContext, useContext, useState, ReactNode } from "react";

interface DashboardContextType {
  menu: number;
  setMenu: (menu: number) => void;
}

interface DashboardProviderProps {
  children: ReactNode;
}

const DashboardContext = createContext<DashboardContextType | undefined>(
  undefined,
);

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error("useDashboard must be used within a DashboardProvider");
  }
  return context;
};

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const [menu, setMenu] = useState<number>(0);

  return (
    <DashboardContext.Provider value={{ menu, setMenu }}>
      {children}
    </DashboardContext.Provider>
  );
};
