import { useEffect, useState } from "react";
import { ApiEndpoint } from "../../shared/apis/apiEndpoint";
import { toast } from "react-toastify";
import { getMessaging, onMessage } from "firebase/messaging";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEraser,
  faKey,
  faLeftLong,
  faSpinner,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import fetchInterceptor from "../../shared/fetchInterceptor";
import { isSupported } from "firebase/messaging";
//@ts-ignore
import CryptoJS from "crypto-js";

const apiEndpoint = ApiEndpoint;

interface Message {
  message: string;
  mine: boolean;
}

async function encrypt(text: string, secret: string) {
  return CryptoJS.AES.encrypt(text, secret).toString();
}

async function decrypt(ciphertext: string, secret: string) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secret);
  return bytes.toString(CryptoJS.enc.Utf8);
}

//@ts-ignore
export default function PrivateChat({ chatId, setMenu, chatUsername }) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageSentLoading, setMessageSentLoading] = useState(false);
  const [deleteChatLoading, setDeleteChatLoading] = useState(false);
  const [deleteMessagesLoading, setDeleteMessagesLoading] = useState(false);
  const [chatSecretExists, setChatSecretExists] = useState(false);

  useEffect(() => {
    async function checkIfSecretExists() {
      //   check if secret is either in local storage or in the session storage
      //   if not, ask for secret

      if (
        !(sessionStorage.getItem("chatSecret" + chatId) === null) ||
        !(localStorage.getItem("chatSecret" + chatId) === null)
      ) {
        setChatSecretExists(true);
      }
    }

    checkIfSecretExists();

    const initializeChat = async () => {
      const supported = await checkSupport();
      if (!supported) {
        const interval = setInterval(() => {
          getMessages();
        }, 3000);

        return () => clearInterval(interval);
      } else {
        onMessage(getMessaging(), (payload) => {
          getMessages();
        });
        await getMessages();
      }
    };
    if (chatSecretExists) {
      initializeChat();
    }
  }, [chatSecretExists]);
  async function checkSupport() {
    return await isSupported();
  }
  async function getMessages() {
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "chat/get-messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatId }),
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status) {
        let secret = "";
        if (sessionStorage.getItem("chatSecret" + chatId) !== null) {
          secret = sessionStorage.getItem("chatSecret" + chatId) as string;
        } else if (localStorage.getItem("chatSecret" + chatId) !== null) {
          secret = localStorage.getItem("chatSecret" + chatId) as string;
        } else {
          setChatSecretExists(false);
          return;
        }

        for (const msg of data.messages) {
          msg.message = await decrypt(msg.message, secret);
        }

        setMessages(data.messages);
        scrollAllToBottom();
        // toast(data.message, { type: "success" });
      } else {
        // toast(data.message, { type: "error" });
      }
    } catch (error) {
      console.log(error);
      toast(
        "Error getting messages. If you recently changed secret, clear the chat.",
        { type: "error" },
      );
    }
  }
  function scrollAllToBottom() {
    const messages = document.getElementsByClassName("privateChatMessages")[0];
    messages.scrollTop = messages.scrollHeight;
  }
  async function sendMessage() {
    setMessageSentLoading(true);
    let secret2 = "";
    if (sessionStorage.getItem("chatSecret" + chatId) !== null) {
      secret2 = sessionStorage.getItem("chatSecret" + chatId) as string;
    } else if (localStorage.getItem("chatSecret" + chatId) !== null) {
      secret2 = localStorage.getItem("chatSecret" + chatId) as string;
    } else {
      setChatSecretExists(false);
      return;
    }
    const encryptedMessage = await encrypt(message, secret2);
    console.log(encryptedMessage);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "chat/send-message",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatId, message: encryptedMessage }),
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status) {
        // toast(data.message, { type: "success" });
        setMessage("");
        setMessageSentLoading(false);
        await getMessages();
      } else {
        toast(data.message, { type: "error" });
        setMessageSentLoading(false);
      }
    } catch (error) {
      toast("Error sending message", { type: "error" });
      setMessageSentLoading(false);
    }
  }
  async function deleteMessages() {
    setDeleteMessagesLoading(true);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "chat/delete-messages",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatId }),
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status) {
        toast(data.message, { type: "success" });
        setMessages([]);
        setDeleteMessagesLoading(false);
      } else {
        toast(data.message, { type: "error" });
        setDeleteMessagesLoading(false);
      }
    } catch (error) {
      toast("Error deleting messages", { type: "error" });
      setDeleteMessagesLoading(false);
    }
  }
  async function deleteChat() {
    setDeleteChatLoading(true);
    try {
      const response = await fetchInterceptor(
        apiEndpoint.getApiEndpoint() + "chat/delete-chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ chatId }),
          credentials: "include",
        },
      );
      const data = await response.json();
      if (data.status) {
        toast(data.message, { type: "success" });
        setMenu("0");
        setDeleteChatLoading(false);
      } else {
        toast(data.message, { type: "error" });
        setDeleteChatLoading(false);
      }
    } catch (error) {
      toast("Error deleting chat", { type: "error" });
      setDeleteChatLoading(false);
    }
  }

  async function handleSecret(event: any) {
    event.preventDefault();
    const secret = event.target[0].value;
    const storage = event.target[1].value;
    if (storage === "Local Storage") {
      localStorage.setItem("chatSecret" + chatId, secret);
    } else {
      sessionStorage.setItem("chatSecret" + chatId, secret);
    }
    setChatSecretExists(true);
  }

  async function removeSecret() {
    localStorage.removeItem("chatSecret" + chatId);
    sessionStorage.removeItem("chatSecret" + chatId);
    setChatSecretExists(false);
  }

  return (
    <>
      {chatSecretExists ? (
        <>
          <div className={"privateChatTopBar"}>
            <button onClick={() => setMenu(1)} className={"chatBackArrow2"}>
              <FontAwesomeIcon icon={faLeftLong} color={"white"} />
            </button>
            <button className={"formOkButton"} onClick={removeSecret}>
              {/*  font awesome settings icon*/}
              <FontAwesomeIcon icon={faKey} />
            </button>
            {deleteMessagesLoading ? (
              <button className={"formOkButton"} disabled={true}>
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </button>
            ) : (
              <button className={"formOkButton"} onClick={deleteMessages}>
                <FontAwesomeIcon icon={faEraser} /> Clear
              </button>
            )}
            {deleteChatLoading ? (
              <button className={"formOkButton"} disabled={true}>
                <FontAwesomeIcon icon={faSpinner} spin={true} />
              </button>
            ) : (
              <button className={"formOkButton"} onClick={deleteChat}>
                <FontAwesomeIcon icon={faTrashCan} /> Delete
              </button>
            )}
          </div>
          <div className={"privateChatContainer"}>
            <div className={"privateChatMessages"}>
              {messages.map((msg, i) => {
                return (
                  <div
                    key={i}
                    className={
                      msg.mine
                        ? "privateChatMessageRight"
                        : "privateChatMessageLeft"
                    }
                  >
                    <div
                      className={"privateChatMessage"}
                      style={{
                        borderTopRightRadius: msg.mine ? "0" : "10px",
                        borderTopLeftRadius: msg.mine ? "10px" : "0",
                      }}
                    >
                      {msg.message}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={"privateChatBottomBar"}>
              <input
                type={"text"}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={message}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    sendMessage();
                  }
                }}
              />
              {messageSentLoading ? (
                <button disabled={true}>
                  <FontAwesomeIcon icon={faSpinner} spin={true} />
                </button>
              ) : (
                <button onClick={sendMessage}>Send</button>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"privateChatTopBar"}>
            <button onClick={() => setMenu(1)} className={"chatBackArrow2"}>
              <FontAwesomeIcon icon={faLeftLong} color={"white"} />
            </button>
          </div>
          <div className={"privateChatContainer"}>
            <form className={"formContainer"} onSubmit={handleSecret}>
              <p>
                You need to enter the secret to decrypt and encrypt the messages
                in this chat.
              </p>
              <p>
                Both you and the other user need to use the same secret to read
                the messages.
                <br />{" "}
                <b style={{ color: "red" }}>
                  Local storage will store the secret until you delete your
                  browser data.
                </b>{" "}
                <br />{" "}
                <b style={{ color: "gold" }}>
                  Session storage will store the secret until you close the tab.{" "}
                </b>
              </p>
              <input type={"text"} />
              <div>
                <input type={"radio"} checked={true} name={"secretStorage"} />
                <label>Local Storage</label>
              </div>
              <div>
                <input type={"radio"} name={"secretStorage"} />
                <label>Session Storage</label>
              </div>
              <button>Submit</button>
            </form>
          </div>
        </>
      )}
    </>
  );
}
